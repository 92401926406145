import { useEffect, useRef, useState } from 'react';
import { Dropdown, Tab, Nav } from 'react-bootstrap';
import UploadDocumentList from './UploadDocumentLIst';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addDocument } from '@src/store/slices/common';
import { updateLeadDetailsInStore } from '@src/store/slices/insurance';
import secureStorage from '@src/config/encrypt';
import React from 'react';
import DocumentGridView from '@src/component/insurance/common/DocumentGridView';
import DocumentView from '@src/component/insurance/common/DocumentView';
import { DOCUMENT_CATEGORY, DOC_STATUS, ROLE_FEATURES, STATUS_SUB_STATUS, APP_TYPE_AGENCY, AGENCY_STATUS, LEAD_TYPE_CRM } from '@src/config/constant';
import { isUserHasAccessPage } from '@src/config/helper';
import { validateInstallmentDocs } from '../common/CommonDocumentFunction';



export default function UploadDocumentview(props: any) {
    let { docType } = props
    let [activeKey, setActiveKey] = useState<string>('first')
    let [allImages, setAllImages] = useState<any[]>([])
    let [leadId, setLeadId] = useState<number>(0)
    let [leadDetail, setLeadDetail] = useState<any>(null)
    let [categoryList, setCategoryList] = useState<any>(null)
    let [activeImage, setActiveImage] = useState<any>(null)
    let [activeImageIndex, setActiveImageIndex] = useState<number>(-1)
    let [selectedTag, setSelectedTag] = useState<any>('Mark as a Tag')
    let [tagImage, setTagImage] = useState<any>(null)
    let [showCategory, setShowCategory] = useState<boolean>(true)
    let [categoryForResubmission, setCategoryForResubmission] = useState<any>(null)
    let [vehicleImageUploaded, setVehicleImageUploaded] = useState<boolean>(false)
    let [showDropdown, setShowDropdown] = useState<boolean>(false)

    let categoryDropdownRef = useRef<any>(null)

    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userId = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.id

    let insuranceState = useSelector((state: any) => state.insurance)
    let commonState = useSelector((state: any) => state.common)
    let dispatch = useDispatch()

    let editAccess = isUserHasAccessPage({ permissionID: ROLE_FEATURES['doc_upload'], accessType: 'edit', assignUserId: `${leadDetail?.assign_user_id}` })

    /**
     * function to update Redux state after various operations such as add, delete....
     * @param documents 
     * @param type 
     * @param subtype 
     */
    const updateLeadDetailInRedux = (documents: any, type: string, doc_status_id: any) => {
        let details = { ...leadDetail }
        let images = (leadDetail && leadDetail.images) || []

        if (type === 'update') {
            let newImages = JSON.parse(JSON.stringify(images))

            let updatedImageArray: any = newImages && newImages.reduce((updatedImageArray: any[], ele: any) => {
                let exists = documents && documents.find((e: any) => e.id === ele.id)

                if (exists) updatedImageArray.push(exists)
                else updatedImageArray.push(ele)

                return updatedImageArray
            }, [])

            details['images'] = updatedImageArray
            //update document status
            if (doc_status_id && details.doc_status_id !== doc_status_id) {
                details['doc_status_id'] = doc_status_id
                details['document_status'] = DOC_STATUS[doc_status_id as keyof typeof DOC_STATUS]
            }
            setLeadDetail(details)
            dispatch(updateLeadDetailsInStore(details))
        }
    }

    /**
     * counts the total number of documents of a specific doc id
     * @param docId 
     * @returns 
     */
    const countImagesOfSameDoc = (docId: number) => {
        let count: number = 0;
        if (allImages && allImages.length) {
            let selectedDoc = allImages.filter((e: any) => e.doc_id === docId)
            count = selectedDoc && selectedDoc.length
        }

        return count
    }

    /**
     * When user changes the category of selected document
     * @param category 
     */
    const handleTagChange = async (category: any) => {
        if (Object.keys(category).length && Object.keys(activeImage).length) {
            props.setLoader(true)

            let validateInstallmentFile: any = validateInstallmentDocs(category, leadDetail, activeImage)
            if (validateInstallmentFile?.error) {
                props.setLoader(false)
                return toast.error(validateInstallmentFile.msg)
            }
            //check for resubmit documents
            let checkResubmitDocs: any = allImages && allImages.filter((e: any) => e.doc_id === category.value && e.is_resubmit === 2)
            checkResubmitDocs = checkResubmitDocs?.length

            if(checkResubmitDocs > 0) {
                toast.error("Category already in resubmission. Cannot upload new document.")
                props.setLoader(false)
                return
            }
            // return false
            let updateObj = [{ id: activeImage.id, document_type_id: docType, document_id: category.value, type: 'update' }]
            let formdata = new FormData()
            formdata.append('user_id', userId)
            formdata.append('lead_id', `${leadId}`)
            formdata.append('doc_path', JSON.stringify(updateObj))

            let updateDocument: any = await addDocument(formdata)
            if (updateDocument && updateDocument.status && updateDocument.status === 200) {
                let updatedImage: any = {
                    id: activeImage.id,
                    doc_id: category.value,
                    doc_type_id: docType,
                    doc_type_name: category.doc_type_name,
                    doc_name: category.label,
                    path: activeImage.path,
                    is_resubmit: activeImage?.is_resubmit,
                    is_approved: activeImage?.is_approved,
                    source: 2
                }
                //find parent category if exists
                let parent: any = {}
                for (let data of categoryList) {
                    if (data.child) {
                        for (let child of data.child) {
                            if (child.value === category.value) {
                                parent = data
                                break
                            }
                        }
                    }
                }

                updatedImage['parent_id'] = parent?.value ?? 0
                updatedImage['parent_name'] = parent?.label ?? ''
                updateLeadDetailInRedux([updatedImage], 'update', updateDocument?.data?.doc_status_id)
                toast.success("Document Updated Successfully")
                setShowDropdown(false)
            }
        }

        props.setLoader(false)
    }

    /**
     * function to handle tag button click or category under click on List of documents
     * When user clicks on tag/doc category on upload section
     * then, redirect to tag image section with selected img
     * @param image 
     */
    const handleImageClick = (image: any) => {
        if (allImages.length) {
            let imageIndx: any = -1
            if (image.id) imageIndx = allImages.findIndex((e: any) => e.id === image?.id)

            if (imageIndx === 0 || imageIndx !== -1) {
                //if image is not assigned any doc yet
                if (image.doc_id === 0) setSelectedTag("Mark as a Tag")
                else {
                    setSelectedTag(image.parent_name ? image.parent_name + "+" + image.doc_name : image.doc_name)
                }
                setActiveImage(image)
                setActiveImageIndex(imageIndx)
                setTagImage(image)
                setShowCategory(true)
                setCategoryForResubmission(null)
                setActiveKey('second')
            }            
        }

        //for setting selected category when user clicks on category on tag Image panel
        if (image && image.value) {
            let requestDoc = allImages && allImages.find((e: any) => e.doc_id === image.value && e.is_resubmit === 2)
            let updatedReqst = requestDoc ? { ...image, alreadyRequested: true } : { ...image, alreadyRequested: false }

            setActiveKey('second')
            setShowCategory(false)
            setCategoryForResubmission(updatedReqst)
            setActiveImage(null)
            setActiveImageIndex(-1)

        }
    }

    /**
     * sets activekey state to current active tab
     * @param activeTab 
     */
    const handleTabChange = (activeTab: string) => {
        if(activeTab === 'first') {
            setActiveImage(null)
            setActiveImageIndex(-1)
            setTagImage(null)
        } else {
            let getImages = (allImages && allImages.length) ? allImages.filter((e: any) => e.path) : []
            if (getImages.length) {
                setActiveImage(getImages[0])
                setActiveImageIndex(0)
                setTagImage(getImages[0])
            }
        }
        setActiveKey(activeTab)
    }

    const updateSelectedImage = (image: any) => {
        if (allImages) {
            let indx = allImages.findIndex((e: any) => e.id === image.id)
            setActiveImage(allImages[indx])
            setActiveImageIndex(indx)
        }

    }

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    }

    useEffect(() => {
        if (commonState && commonState.documentList) {
            let documentList = JSON.parse(JSON.stringify(commonState.documentList))
            let updatedList = documentList.filter((e: any) => {
                if (insuranceState?.getInboundPaymentFrom === "broker" && e.value === DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_inbound'] && insuranceState?.leadDetail?.pay_tenure) {
                    e['child'] = [...e.child, e]
                }


                //IN CASE OF AGENCY SHOW INSTALMENT DOCS FOR DOC TYPE 1
                // if(insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && insuranceState.leadDetail.payment_tenure_id){
                //     documentList = documentList.map((e: any) => { 
                //         if(e?.child?.length && e.parent_id === 12){ 
                //             e.doc_type_id = props.docType;
                //             e.label = "Proof of Payment"
                //             e.is_required = true
                //             e.child = e.child.map((child: any)=> { 
                //                 if(child.value>14 && child.value<27) child.doc_type_id = props.docType; child.is_required = true; return child 
                //             })
                //         }
                //         return e;
                //     });
                // }

                return (e.doc_type_id === docType) && (+e.value !== DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_customer_partner_broker'] || (+e.value === DOCUMENT_CATEGORY['postDisbursal']['childs']['proof_customer_partner_broker'] && ((insuranceState.getInboundPaymentFrom === "broker" && !insuranceState.leadDetail.inbound_amount) || (insuranceState.leadDetail.inbound_amount && insuranceState.leadDetail.pay_from_cust_to_partner_broker_id)))) && (e.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] && e.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value'])
            })

            setCategoryList(updatedList)
        }
    // eslint-disable-next-line
    }, [commonState.documentList])


    useEffect(() => {
        let leadDetail = insuranceState && insuranceState.leadDetail
        let leadId = leadDetail && leadDetail.leadId

        if (leadId) {
            setLeadId(leadId)
            setLeadDetail(leadDetail)

            let images = (leadDetail && leadDetail.images) || []

            if (images && images.length) {
                let currentDocTypeImages = []
                for (let img of images) {
                    if (img.doc_type_id === docType || img.doc_id === 0) {
                        currentDocTypeImages.push(img)
                    }
                }

                let firstImage = currentDocTypeImages[activeImageIndex]
                if (firstImage) {
                    //if image is not assigned any doc yet
                    if (firstImage.doc_id === 0) setSelectedTag("Mark as a Tag")
                    else {
                        setSelectedTag(firstImage.parent_name ? firstImage.parent_name + "+" + firstImage.doc_name : firstImage.doc_name)
                    }
                    setActiveImage(firstImage)
                }
                setAllImages(currentDocTypeImages)
            }

            if (!images || !images.length) {
                setAllImages([])
            }

            setTagImage(null)
        }
    // eslint-disable-next-line
    }, [insuranceState.leadDetail, activeImage, activeImageIndex, props])

    useEffect(() => {
        if (vehicleImageUploaded) {
            setVehicleImageUploaded(false)
        }
        // eslint-disable-next-line
    }, [allImages])

    useEffect(() => {
        //hides category dropdown if user click anywhere on screen other than dropdown
        const handleDocumentClick = (e: any) => {
            if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(e.target)) {
                setShowDropdown(false);
            }
        }

        document.body.addEventListener('click', handleDocumentClick);

        return (() => {
            document.body.removeEventListener('click', handleDocumentClick);
        })

    }, [])

    return (
        <div className="uplaod-view-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeKey}>

                <Nav variant="pills" className="flex-column">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link eventKey="first" onClick={() => handleTabChange('first')}>Upload</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second" onClick={() => handleTabChange('second')}>Tag Image</Nav.Link>
                        </Nav.Item>
                    </div>

                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        {
                            activeKey === 'first' && <DocumentGridView callFrom='UploadDocView' allImages={allImages} handleImageClick={handleImageClick} setLoader={props.setLoader} docType={docType} />
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey='second'>

                        {
                            allImages && allImages.filter((e: any) => e.path).length
                                ?
                                <div className="tag-image-bx">

                                    {
                                        showCategory ?
                                            <div className="image-tagning-drop-down tag-category-sub-category" ref={categoryDropdownRef}>

                                                <Dropdown show={showDropdown}>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={handleDropdownToggle} disabled={
                                                        ((insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && [AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceState?.leadDetail?.agency_status))) ||
                                                        (activeImage?.doc_id && ((activeImage?.is_approved === 1 || activeImage?.is_resubmit === 2) || ((docType === 2 && (leadDetail?.sub_status_id >= 7))) || ((docType === 3 && leadDetail?.sub_status_id >= 12 && leadDetail?.sub_status_id !== STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker'])) || leadDetail?.status_id === 6 || !editAccess || (docType === 1 && (leadDetail?.sub_status_id >= 4 || +leadDetail?.sub_status_id === 3)) || (activeImage.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] || activeImage.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value'])))}>
                                                        <div className="select-category">
                                                            {
                                                                selectedTag && selectedTag.includes('+')
                                                                    ?
                                                                    <>
                                                                        <h4>
                                                                            {
                                                                                selectedTag.split('+')[0]
                                                                            }
                                                                        </h4>
                                                                        <h3>
                                                                            {
                                                                                selectedTag.split('+')[1]
                                                                            }
                                                                        </h3>
                                                                    </>
                                                                    : selectedTag
                                                            }
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="taging-list-drop-down">
                                                            <ul>
                                                                {
                                                                    categoryList && categoryList.length
                                                                        ?
                                                                        categoryList.map((category: any, indx: number) => {
                                                                            let child = category.child
                                                                            if (child.length) {
                                                                                return (
                                                                                    <React.Fragment key={indx}>
                                                                                        <li>
                                                                                            {category.label}

                                                                                            <span className="stepprogress"><i className={(category === DOCUMENT_CATEGORY['firstSubmit']['childs']['vehicle_photos'] && vehicleImageUploaded) ? "ic-check" : (category !== DOCUMENT_CATEGORY['firstSubmit']['childs']['vehicle_photos'] && allImages?.filter((e: any) => e.parent_id === category.value)?.length ? 'ic-check' : '')}></i></span>
                                                                                            <ul>
                                                                                                {child.map((ele: any, idx: number) => {
                                                                                                    let imageExistsForCat = allImages && allImages.find((e: any) => e.doc_id === ele.value && e.path)
                                                                                                    let checkIcon = imageExistsForCat ? true : false
                                                                                                    if (checkIcon && !vehicleImageUploaded) setVehicleImageUploaded(true)

                                                                                                    let count = countImagesOfSameDoc(ele.value)

                                                                                                    return (
                                                                                                        <li key={`child_${idx}`} onClick={() => handleTagChange(ele)}>
                                                                                                            {ele.is_required ? ele.label + "*" : ele.label}
                                                                                                            <span className="doc_count">{count}</span>
                                                                                                            <span className="stepprogress"><i className={checkIcon ? "ic-check" : ''}></i></span>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </li>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                            else {
                                                                                let imageExistsForCat = allImages && allImages.find((e: any) => e.doc_id === category.value && e.path)
                                                                                let checkIcon = imageExistsForCat ? true : false
                                                                                let count = countImagesOfSameDoc(category.value)
                                                                                return (
                                                                                    <React.Fragment key={indx}>
                                                                                        <li onClick={() => handleTagChange(category)}>
                                                                                            {category.is_required ? category.label + "*" : (category.conditional_mandatory && category.conditional_mandatory.length ? category.label + "**" : category.label)}
                                                                                            <span className="doc_count">{count}</span>
                                                                                            <span className="stepprogress"><i className={checkIcon ? "ic-check" : ''}></i></span>
                                                                                        </li>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        })
                                                                        :
                                                                        ""
                                                                }
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            : null
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            activeKey === "second" && <DocumentView callFrom="UploadDocView" allImages={allImages} updateSelectedImage={updateSelectedImage} setLoader={props.setLoader} currentActiveImage={tagImage} showImages={showCategory} categoryForResubmission={categoryForResubmission} docType={docType} />
                        }
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
            <div className="document-list-panel">
                <UploadDocumentList docType={props.docType} images={allImages} countImagesOfSameDoc={countImagesOfSameDoc} handleImageClick={handleImageClick} />
            </div>
        </div >

    )
}