import { addDocument } from "@src/store/slices/common";
import { useEffect, useState } from "react";
import { markDocApproved, updateLeadDetailsInStore, requestDocResubmission, viewDocumentHistory } from '@src/store/slices/insurance';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DocumentHistory from "@src/component/insurance/common/DocumentHistory";
import ResubmissionModal from "@src/component/insurance/common/DocumentResubmission";
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { DOCUMENT_CATEGORY, DOC_STATUS, ROLE_FEATURES, STATUS_SUB_STATUS, APP_TYPE_AGENCY, AGENCY_STATUS, LEAD_TYPE_CRM } from '@src/config/constant';
import { isUserHasAccessPage } from "@src/config/helper";
/* eslint-disable jsx-a11y/anchor-is-valid */
export default function DocumentView(props: any) {
    let { allImages, callFrom, currentActiveImage, showImages, categoryForResubmission } = props
    let [activeImage, setActiveImage] = useState<any>(null)
    let [activeImageIndex, setActiveImageIndex] = useState<number>(-1)
    let [leadId, setLeadId] = useState<any>()
    let [showHistoryModal, setShowHistoryModal] = useState<boolean>(false)
    let [documentHistoryData, setDocumentHistoryData] = useState<any>(null)
    let [resubmissionComment, setResubmissionComment] = useState<any>(null)
    let [resubmissionModal, setResubmissionModal] = useState<any>(false)
    let [docCount, setDocCount] = useState<any>(null)
    let [leadSubstatus, setLeadSubstatus] = useState(0)
    let [leadDetail, setLeadDetail] = useState<any>({})
    let [sliderImages, setSilderImages] = useState<any>([])
    let [activeDocExtension, setActiveDocExtension] = useState<string>('')

    const dispatch = useDispatch()
    const insuranceState = useSelector((state: any) => state.insurance)

    let editAccess = isUserHasAccessPage({ permissionID: ROLE_FEATURES['doc_upload'], accessType: 'edit', assignUserId: `${leadDetail?.assign_user_id}` })

    const resetImagePosition = () => {
        let imgBox: any = document.querySelector('.active-img');
        if (imgBox) {
            // let width = imgBox.width
            // let height = imgBox.height
            imgBox.setAttribute('style', `width: ${418}px; height: ${284}px`)
        }
    }

    /**
     * handles image selection (prev && next)
     * @param type 
     */
    const handleImageChange = (type: string) => {

        if (type === 'next') {
            let updatedIndex = activeImageIndex + 1
            let newImage = sliderImages[updatedIndex]

            props.updateSelectedImage(newImage)
            setActiveImage(newImage)
            setActiveImageIndex(updatedIndex)
        }

        if (type === 'previous') {
            let updatedIndex = activeImageIndex - 1
            let newImage = sliderImages[updatedIndex]

            props.updateSelectedImage(newImage)
            setActiveImage(newImage)
            setActiveImageIndex(updatedIndex)
        }

        resetImagePosition()
    }


    /**
     * function to update Redux state after various operations such as add, delete....
     * @param documents 
     * @param type 
     * @param subtype 
     */
    const updateLeadDetailInRedux = (params: any) => {
        let { documents, type, updateStateData, doc_status_id } = params;

        let leadDetails = insuranceState && insuranceState.leadDetail
        let details = JSON.parse(JSON.stringify(leadDetails))

        let images = (details && details.images) || []

        if (type === 'add' && documents.length) {
            let updatedImages = [...images, ...documents]

            details['images'] = updatedImages

            //update document status
            if (doc_status_id && details.doc_status_id !== doc_status_id) {
                details['doc_status_id'] = doc_status_id
                details['document_status'] = DOC_STATUS[doc_status_id as keyof typeof DOC_STATUS]
            }

            dispatch(updateLeadDetailsInStore(details))
        }

        if (type === 'delete') {
            let updatedImages = images && images.filter((e: any) => e.id !== documents.id)

            details['images'] = updatedImages
            dispatch(updateLeadDetailsInStore(details))
        }

        if (type === 'update') {
            let newImages = JSON.parse(JSON.stringify(images))

            let updatedImageArray: any = newImages && newImages.reduce((updatedImageArray: any[], ele: any) => {
                let exists = documents && documents.find((e: any) => e.id === ele.id)

                if (exists) updatedImageArray.push(exists)
                else updatedImageArray.push(ele)

                return updatedImageArray
            }, [])

            details['images'] = updatedImageArray;
            if (updateStateData) {
                details = { ...details, ...updateStateData };
            }

            //update document status
            if ((doc_status_id || doc_status_id === 0) && details.doc_status_id !== doc_status_id) {
                details['doc_status_id'] = doc_status_id
                details['document_status'] = DOC_STATUS[doc_status_id as keyof typeof DOC_STATUS]
            }

            dispatch(updateLeadDetailsInStore(details))
        }
    }


    /**
     * function to handle various button clicks
     * @param type 
     */
    const handleBtnClick = async (type: string) => {
        if (type === 'history') {
            fetchDocumentHistory()
            setShowHistoryModal(true)
        }

        if (type === 'approve') {
            let updatedApprovedImages = allImages && allImages.filter((e: any) => e.doc_id === activeImage.doc_id)
            markImageApprove(updatedApprovedImages)
        }

        if (type === 'resubmission') {
            if (!showImages && categoryForResubmission) {
                let resubDoc = {
                    document_id: categoryForResubmission.value,
                    type: 'newDocSubmission',
                    lead_id: leadId,
                    comment: resubmissionComment
                }
                requestResubmission(resubDoc, 'newDoc')
            }
            else {
                let resubDocs = allImages && allImages.filter((e: any) => e.doc_id === +activeImage.doc_id)
                requestResubmission(resubDocs)
            }

            hideModalResubmission()
        }

        if (type === 'removeTag') {
            props.setLoader(true)
            let formdata = new FormData()

            let updatedActiveImage = { ...activeImage, doc_id: 0, parent_id: 0 }
            let docPath = [{ document_type_id: activeImage.doc_type_id, document_id: 0, type: 'update', id: activeImage.id }]

            formdata.append('lead_id', leadId)
            formdata.append('doc_path', JSON.stringify(docPath))

            let updateCategory: any = await addDocument(formdata)
            if (updateCategory && updateCategory.status && updateCategory.status === 200) {
                updateLeadDetailInRedux({ documents: [updatedActiveImage], type: 'update', doc_status_id: updateCategory?.data?.doc_status_id })
                toast.success("Document untag Succesful")
            }
            props.setLoader(false)
        }
    }

    /**
  * function to fetch lead history of selected document
  */
    const fetchDocumentHistory = async () => {
        props.setLoader(true)

        if (categoryForResubmission && Object.keys(categoryForResubmission).length && categoryForResubmission?.value) {
            const getHistory: any = await viewDocumentHistory({ document_id: categoryForResubmission.value, lead_id: leadId })
            if (getHistory && getHistory.status && getHistory.status === 200) {
                let data = getHistory.data
                setDocumentHistoryData(data)
            }
        }

        else if (activeImage) {
            let activeImageDocId = activeImage.doc_id

            const getHistory: any = await viewDocumentHistory({ document_id: activeImageDocId, lead_id: leadId })
            if (getHistory && getHistory.status && getHistory.status === 200) {
                let data = getHistory.data
                setDocumentHistoryData(data)
            }
        }
        props.setLoader(false)

    }

    /**
    * function to mark selected Images as Approved
    * @param images 
    */
    const markImageApprove = async (images: Array<any>) => {
        props.setLoader(true)

        let approveImage = []
        let allDocAlreadyApproved = true
        let updatedImages = JSON.parse(JSON.stringify(images))

        for (let image of updatedImages) {
            let isDocIdExist = approveImage.includes(image.doc_id)
            if (!isDocIdExist) {
                let activeImageDocId = image.doc_id
                approveImage.push(activeImageDocId)
            }

            allDocAlreadyApproved = false
            image['is_approved'] = 1
        }

        if (approveImage.length) {
            const markApprove: any = await markDocApproved({ document_id: approveImage, lead_id: leadId })
            if (markApprove && markApprove.status && markApprove.status === 200) {
                updateLeadDetailInRedux({ documents: updatedImages, type: 'update', updateStateData: { doc_status_id: markApprove?.data?.doc_status_id, document_status: markApprove?.data?.doc_status_value } });


                toast.success("Document Approved Successfully")
            }
        }

        if (allDocAlreadyApproved) {
            toast.error("Already Approved")
        }

        props.setLoader(false)
    }

    /**
 * function to mark selected images for Resubmission
 * @param documents 
 */
    const requestResubmission = async (documents: any, type?: string) => {
        props.setLoader(true)

        let resubmitDocs = []
        let allDocAlreadyResubmitted = true
        let updatedDocs = JSON.parse(JSON.stringify(documents))
        let resubDocs: any = []

        if (type && type === 'newDoc') {
            const requestResub: any = await requestDocResubmission(documents)
            if (requestResub && requestResub.status && requestResub.status === 200) {
                updateLeadDetailInRedux({ documents: [{ doc_id: categoryForResubmission.value, doc_type_id: categoryForResubmission.doc_type_id, is_resubmit: 2, path: '' }], type: 'add', doc_status_id: requestResub?.data?.doc_status_id })

                categoryForResubmission.alreadyRequested = true
            
                toast.success("Document submission Request Raised Successfully")
            }
        }

        else {
            for (let image of updatedDocs) {
                if (!image.is_approved && image.is_resubmit !== 2) {
                    let isExists = resubDocs.find((e: any) => e.doc_id === image.doc_id)
                    if (!isExists) {
                        // let activeImageId = image.id
                        let activeImageDocId = image.doc_id
                        resubmitDocs.push(activeImageDocId)
                        allDocAlreadyResubmitted = false
                        image['is_resubmit'] = 2
                    }

                }
            }

            if (resubmitDocs.length) {
                let apiParams: any = {
                    document_id: resubmitDocs[0],
                    lead_id: leadId
                }

                if (resubmissionComment) apiParams['comment'] = resubmissionComment

                const requestResub: any = await requestDocResubmission(apiParams)
                if (requestResub && requestResub.status && requestResub.status === 200) {
                    updateLeadDetailInRedux({ documents: updatedDocs, type: 'update', subType: 'resubmit', updateStateData: { doc_status_id: requestResub?.data?.doc_status_id, document_status: requestResub?.data?.doc_status_value } })
                    toast.success("Document Resubmission Request Raised Successfully")
                }
            }

            if (allDocAlreadyResubmitted) {
                toast.error("Documents are already Marked")
            }
        }

        props.setLoader(false)
    }

    const hideModalResubmission = () => {
        setResubmissionModal(false)
    }

    const handleHistoryModalClose = () => {
        setShowHistoryModal(false)
    }

    const setResubmissionContent = (value: string) => {
        setResubmissionComment(value)
    }

    const checkImageType = () => {
        if (activeImage && activeImage.path) {
            let activeImgfile = activeImage?.path?.toLowerCase()?.split('?')?.[0]
            let activeImgFileType = activeImgfile?.split('.')
            activeImgFileType = activeImgFileType?.[activeImgFileType?.length - 1]

            let fileType = 'image'

            if (activeImgFileType === 'pdf') fileType = 'pdf'
            if (['csv', 'xls', 'xlsx'].includes(activeImgFileType)) fileType = "excel"
            if (activeImgFileType === 'doc' || activeImgFileType === "docx") fileType = "word"

            setActiveDocExtension(fileType)
        }
        return
    }

    useEffect(() => {
        //if props has currentActiveImage 
        if (currentActiveImage && allImages) {
            if (callFrom !== 'DocGalleryView') {
                let sliderImg = allImages.filter((e: any) => e.path)
                let availableImgs = (sliderImages && sliderImages.length) ? sliderImages : sliderImg
                let curImageIndx = availableImgs.findIndex((e: any) => e.id === currentActiveImage.id)

                setActiveImage(currentActiveImage)
                setActiveImageIndex(curImageIndx)
                setSilderImages(sliderImg)                
            }
            else {
                let curImageIndx = allImages.findIndex((e: any) => e.id === currentActiveImage.id)
                setActiveImage(currentActiveImage)
                setActiveImageIndex(curImageIndx)
                setSilderImages(allImages)
            }

        }


        else if (allImages) {            
            let curActiveImage: any;
            if (callFrom !== 'DocGalleryView') {
                let sliderImg = allImages.filter((e: any) => e.path)
                let curActiveImgIndex = sliderImg[activeImageIndex] ? activeImageIndex : -1
                curActiveImage = sliderImg[curActiveImgIndex]
                setActiveImage(curActiveImage)
                setActiveImageIndex(curActiveImgIndex)
                setSilderImages(sliderImg)

            }
            else {
                let curActiveImgIndex = allImages[activeImageIndex] ? activeImageIndex : 0
                curActiveImage = allImages[curActiveImgIndex]
                setActiveImage(curActiveImage)
                setActiveImageIndex(curActiveImgIndex)
                setSilderImages(allImages)                
            }

            if (curActiveImage) {
                props.updateSelectedImage(curActiveImage)
            }
        }


         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allImages, currentActiveImage])


    useEffect(() => {
        let leadDetails = insuranceState && insuranceState.leadDetail
        if (leadDetails && leadDetails.leadId) {
            setLeadSubstatus(leadDetails.sub_status_id)
            setLeadId(leadDetails.leadId)
            setLeadDetail(leadDetails)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceState.leadDetail])


    useEffect(() => {
        if (props && props.selectedCategory && Object.keys(props.selectedCategory).length) {
            let parentCategoryObj = props.selectedCategory

            if (parentCategoryObj.category || parentCategoryObj.subcategory) {
                let maxCount = 0
                let totalDocCount = props.selectedCategoryImage.length ? (props.selectedCategoryImage.filter((e: any) => e.path)).length : 0

                if (parentCategoryObj.subcategory) {
                    let subcat = parentCategoryObj.subcategory
                    maxCount = subcat.max_upload
                }
                else {
                    let cat = parentCategoryObj.category
                    maxCount = cat.max_upload
                }
                let count = `${totalDocCount}/${maxCount}`
                setDocCount(count)
            }

            //reset index on category change
            setActiveImageIndex(0)

        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedCategory, props.selectedCategoryImage])

    useEffect(() => {
        if (activeImage && activeImage.path) {
            checkImageType()
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeImage])
    console.log("leadDetail", leadDetail.source)
    return (
        <>
            {
                callFrom === 'DocGalleryView'
                    ?
                    <div className="doc-count">
                        Documents
                        {
                            <span> {docCount}</span>
                        }
                    </div>
                    : null
            }
            {
                editAccess && 
                ((insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceState.leadDetail?.agency_status)) || insuranceState?.leadDetail?.application_type_id !== APP_TYPE_AGENCY) 
                && callFrom === 'UploadDocView' && showImages && activeImage && activeImage.doc_id && !activeImage.is_approved && activeImage?.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] && activeImage?.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value'] &&
                    ((leadSubstatus < STATUS_SUB_STATUS['subStatus']['policy_generated'] && activeImage.is_resubmit !== 2 && leadSubstatus !== STATUS_SUB_STATUS['subStatus']['approval_in_progress']) ||
                        (leadSubstatus <= STATUS_SUB_STATUS['subStatus']['policy_generated'] && props.docType === DOCUMENT_CATEGORY['disbursal']['doc_type_id']))
                    ?
                    <div className="remove-tag-txt" onClick={() => handleBtnClick('removeTag')}>Remove Tag</div>
                    : null
            }
            {
                (!showImages) || (callFrom === "DocGalleryView" && (!activeImage || !activeImage?.path))
                    ?
                    <div className="verfication-img-no-image">
                        {
                            <>
                                <img src="/images/noimage.svg" className="no-img" alt='no-img-icon' />
                                <span style={{ display: 'inherit', textAlign: 'center', fontSize: '14px', fontWeight: '400px', color: 'black' }}>No Document Attached</span>
                            </>
                        }

                    </div>
                    : null
            }
            {
                showImages && activeImage && activeImage.path ?
                    // <div className='tag-img-parent' onTouchMove={(event) => {
                    <div className='verfication-img' onTouchMove={(event) => {
                        let fileType = activeImage.path.split('.')
                        fileType = fileType[fileType.length - 1]

                        let isTouchEvntActive = fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' ? true : false

                        if (isTouchEvntActive) {
                            event.preventDefault();
                        }
                    }}
                    >

                        <div className="carousel image-varification-slider">
                            <ul className="carousel__slides">

                                <li className="carousel__slide carousel__slide--active">
                                    <div className="carousel-slideimg pfd-img-height">
                                        {
                                            activeDocExtension !== 'image' ?
                                                <a href={activeImage.path} target="_blank" rel="noreferrer"><i className={`ic-${activeDocExtension}-file pdf-icons-normal-gallery`}></i></a>

                                                :
                                                activeImageIndex >= 0 &&
                                                <ReactPanZoom
                                                    image={activeImage?.path}
                                                    alt="Image alt text"
                                                />
                                        }

                                    </div>
                                </li>

                            </ul>

                            <div className="image-opt">
                                <ul>

                                    {/* if previous image exists */}
                                    {(sliderImages[activeImageIndex - 1])
                                        ?
                                        <li className="icn-pre-slide" onClick={() => handleImageChange('previous')}>
                                            <a><i className="ic-keyboard_arrow_left"></i></a>
                                        </li>
                                        : ""
                                    }
                                    {(sliderImages[activeImageIndex + 1])
                                        ?
                                        <li className="icn-next-slide" onClick={() => handleImageChange('next')}>
                                            <a><i className="ic-keyboard_arrow_right"></i></a>
                                        </li>
                                        :
                                        ""
                                    }

                                </ul>
                            </div>

                            <div className="image-opt doc-image-opt">
                                <div className="btn-submission-approved">
                                    {
                                        (activeImage && activeImage?.doc_id && activeImage?.path) ||
                                            (leadDetail && leadDetail?.source === 2 &&  categoryForResubmission &&categoryForResubmission?.value) ||
                                            (leadDetail && leadDetail?.source === 1 && leadDetail?.doc_status_id > 0 && categoryForResubmission &&categoryForResubmission.value)

                                            ?
                                            <button className="btn-line" onClick={() => handleBtnClick('history')}>View History</button>
                                            :
                                            null
                                    }
                                    {
                                        activeImage && activeImage.is_resubmit === 2
                                            ?
                                            <button className="btn-fill">
                                                <i className="ic-check"></i>
                                                Re-submission Requested
                                            </button>
                                            :
                                            props.docType === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id'] && leadSubstatus < STATUS_SUB_STATUS['subStatus']['policy_generated'] && activeImage && activeImage.doc_id && !activeImage.is_approved
                                                && editAccess && (leadDetail?.source !== 2 || (leadDetail?.source === 2 && leadDetail.lead_type === LEAD_TYPE_CRM)) && leadSubstatus !== STATUS_SUB_STATUS['subStatus']['approval_in_progress'] && activeImage.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] && activeImage.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value'] && ((insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceState?.leadDetail?.agency_status)) || insuranceState?.leadDetail?.application_type_id !== APP_TYPE_AGENCY)  // hide resubmission icon if lead is created in web
                                                ?
                                                <button className="btn-line" onClick={() => setResubmissionModal(true)} >Request Re-submission</button>
                                                : null
                                    }
                                    {
                                        (activeImage && activeImage.is_approved === 1)
                                            ?
                                            <button className="btn-line btn-approve"><i className="ic-check"></i>Approved</button>
                                            :
                                            editAccess && ((insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceState?.leadDetail?.agency_status)) || insuranceState?.leadDetail?.application_type_id !== APP_TYPE_AGENCY)  && activeImage && activeImage.doc_id && activeImage.is_resubmit !== 2 &&
                                                ((props.docType === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id'] &&
                                                    leadSubstatus < STATUS_SUB_STATUS['subStatus']['policy_generated'] && leadSubstatus !== STATUS_SUB_STATUS['subStatus']['approval_in_progress'])
                                                    || (props.docType === DOCUMENT_CATEGORY['disbursal']['doc_type_id'] &&
                                                        leadSubstatus < STATUS_SUB_STATUS['subStatus']['policy_shared']))
                                                ?
                                                <button className="btn-line" onClick={() => handleBtnClick('approve')}>Mark as Approved</button>
                                                : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {
                !showImages ?
                    <div className="image-opt doc-image-opt">
                        <div className="btn-submission-approved">
                            {
                                (activeImage && activeImage.doc_id && activeImage.path) ||
                                    (leadDetail?.source === 2 && categoryForResubmission?.value) ||
                                    (leadDetail?.source === 1 && leadDetail?.doc_status_id > 0 && categoryForResubmission?.value)

                                    ?
                                    <button className="btn-line" onClick={() => handleBtnClick('history')}>View History</button>
                                    : null
                            }
                            {
                                +leadSubstatus !== 3 && leadSubstatus < 4 && props.docType === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id'] && leadDetail?.source !== 2
                                    && editAccess && 
                                    ((insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && [AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceState?.leadDetail?.agency_status)) || insuranceState?.leadDetail?.application_type_id !== APP_TYPE_AGENCY)  && leadDetail?.source === 1 && leadDetail?.doc_status_id > 0
                                    ?
                                    <button className="btn-line" disabled={(callFrom === 'UploadDocView' && categoryForResubmission?.alreadyRequested) || (callFrom !== 'UploadDocView' && !activeImage?.path && activeImage?.is_resubmit === 2)} onClick={() => setResubmissionModal(true)} >{(callFrom === 'UploadDocView' && categoryForResubmission?.alreadyRequested) || ((callFrom !== 'UploadDocView' && !activeImage?.path && activeImage?.is_resubmit === 2)) ? "Doc Submission Requested" : "Request Doc submission"}</button>
                                    : null
                            }

                        </div>
                    </div>
                    : null
            }
            {

                <div className={callFrom === 'UploadDocView' ? "image-opt doc-image-opt" : "image-opt"}>
                    {
                        showHistoryModal ?
                            <DocumentHistory ViewTimeLine={showHistoryModal} handleClose={handleHistoryModalClose} data={documentHistoryData} />
                            :
                            null
                    }
                </div>

            }
            {
                resubmissionModal
                    ?

                    <ResubmissionModal show={resubmissionModal} handleClose={hideModalResubmission} handleBtnClick={handleBtnClick} setResubmissionContent={setResubmissionContent} />
                    : null
            }

        </>
    )
}